import { useIsAuthenticated } from "react-auth-kit";
import { Navigate, Outlet } from "react-router-dom";

export default function CheckAuthentication() {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();

    if (auth) {
        return <Outlet />;
    }

    return <Navigate to="/login" />;
}
