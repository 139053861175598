export const LEGEND_LIST = [
    {
        color: "#B6ECEF",
        label: "2000年以前",
    },
    {
        color: "#1F83DF",
        label: "2000-2010年",
    },
    {
        color: "#080891",
        label: "2011-2020年",
    },
];

export const AUTH_NAME = "city-update";

export const SPRITE_URL = "https://fz-public.oss-cn-wuhan-lr.aliyuncs.com/cu";

// 高亮显示的图层
export const HIGHLIGHT_SOURCE = "sonic-highlight-source";
export const HIGHLIGHT_FILL = "sonic-highlight-fill";
export const HIGHLIGHT_LINE = "sonic-highlight-line";
