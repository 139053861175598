import type { IViewState } from "@sonic/types";

export const DEFAULT_VIEW_STATE: IViewState = {
    bearing: 0,
    latitude: 30.5610110364767,
    longitude: 114.28763036172325,
    maxZoom: 16.9,
    minZoom: 7,
    pitch: 0,
    zoom: 8.5,
    maxBounds: [106.72108928745195, 25.9262932206328, 119.83017628482281, 34.24234776253667],
};
