import type { IViewState } from "@sonic/types";
import type { MapRef } from "react-map-gl";
import { MAPBOX_STYLE } from "@sonic/constant";
import { proxy, ref } from "valtio";
import { devtools } from "valtio/utils";

export interface IPopup {
    content: any;
    id?: number;
    latitude: number;
    longitude: number;
    offsetTop?: number;
}

interface IState {
    allowInteractive: any;
    hoverPopup: IPopup | null;
    // 地图交互
    isCompareSwiper: boolean;
    // 开启滑动
    isSplit: boolean;
    // 主副地图 ref
    map1Ref: { map1: MapRef | null };
    map2Ref: { map2: MapRef | null };
    // 开启对比
    popups: IPopup[];
    primaryMapStyle: any;
    // 主底图style加载完成
    showPopup: boolean;
    // 是否显示气泡
    swiperOffset: number;
    // 滑动条偏移量
    viceMapStyle?: any;
    // 副底图style加载完成
    viewState: IViewState | null;
    dragRotate?: boolean;
    showTileBoundaries?: boolean;
    sprite?: string;
}

export const store: IState = proxy({
    allowInteractive: {
        // 禁止双击放大
        doubleClickZoom: false,
    },
    hoverPopup: null,
    isCompareSwiper: false,
    isSplit: false,
    map1Ref: ref({ map1: null }),
    map2Ref: ref({ map2: null }),
    popups: [] as IPopup[],
    primaryMapStyle: null,
    showPopup: false,
    swiperOffset: 0,
    viceMapStyle: MAPBOX_STYLE.wukan,
    viewState: null,
    dragRotate: true,
    showTileBoundaries: false,
    sprite: "",
});

devtools(store, { enabled: true, name: "@sonic/map-gl" });
