import { AUTH_NAME, DEFAULT_VIEW_STATE, SPRITE_URL } from "@/constant";
import loadable from "@loadable/component";
import { IS_DEV, MAPBOX_STYLE } from "@sonic/constant";
import { MapProvider, ViewStateProvider } from "@sonic/map-gl";
import { QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import zhCN from "antd/locale/zh_CN";
import { AuthProvider } from "react-auth-kit";

import { Navigate, Route, Routes } from "react-router-dom";

import CheckAuthentication from "./pages/check-authentication";
import { queryClient } from "./query-client";

const Layout = loadable(() => import("./pages/layout"));
const OneMap = loadable(() => import("./pages/one-map"));
const Login = loadable(() => import("./pages/login/login"));

function InitApp() {
    return null;
}

function App() {
    // 生产环境允许 debug
    const [searchParams] = useSearchParams();
    const isDebug = Boolean(searchParams.get("debug"));

    return (
        <ConfigProvider
            locale={zhCN}
            theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                    // 主色
                    colorPrimary: "#1570EF",
                    // 取消字体
                    fontFamily: "unset",
                },
            }}
        >
            <QueryClientProvider client={queryClient}>
                <ViewStateProvider
                    viewState={DEFAULT_VIEW_STATE}
                    primaryMapStyle={MAPBOX_STYLE.darkBlue}
                    showTileBoundaries={IS_DEV || isDebug}
                    sprite={SPRITE_URL}
                >
                    <MapProvider>
                        <AuthProvider
                            authType="cookie"
                            authName={AUTH_NAME}
                            cookieDomain={window.location.hostname}
                            cookieSecure={window.location.protocol === "https:"}
                        >
                            <InitApp />

                            <Routes>
                                <Route path="login" element={<Login />} />

                                <Route element={<CheckAuthentication />}>
                                    <Route path="/" element={<Layout />}>
                                        <Route index element={<Navigate to="one-map" />} />

                                        <Route path="one-map" element={<OneMap />} />
                                    </Route>
                                </Route>
                            </Routes>
                        </AuthProvider>
                    </MapProvider>
                </ViewStateProvider>
            </QueryClientProvider>
        </ConfigProvider>
    );
}

export default App;
