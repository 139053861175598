import type { IViewState } from "@sonic/types";
import type { PropsWithChildren } from "react";
import { createContext } from "react";

import { setDragRotate, setPrimaryMapStyle, setShowTileBoundaries, setSprite, setViewState } from "./store/actions";

interface IProps {
    primaryMapStyle: any;
    viewState: IViewState;
    dragRotate?: boolean;
    showTileBoundaries?: boolean;
    sprite?: string;
}

const ViewStateContext = createContext<{ viewState: IViewState } | null>(null);

export function ViewStateProvider(props: PropsWithChildren<IProps>) {
    const { children, viewState, primaryMapStyle, dragRotate = true, showTileBoundaries = false, sprite = "" } = props;

    useMount(() => {
        setViewState(viewState);
        setPrimaryMapStyle(primaryMapStyle);
        setDragRotate(dragRotate);
        setShowTileBoundaries(showTileBoundaries);
        setSprite(sprite);
    });

    return <ViewStateContext.Provider value={null}>{children}</ViewStateContext.Provider>;
}
