// 地图相关
export const MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1IjoiczU4MzgzNzg4IiwiYSI6ImNreWI4bTg4ZDBkMzAyd3A4MGs1OXhjamQifQ.EGVAwrEWnSaxQq8weQrrow";

// light 是mapbox浅色图
// gray是北湖在用, 绿色水系
// pureGray 一桩一码, 灰色水系
export const MAPBOX_STYLE = {
    light: "mapbox://styles/s58383788/cldik4eyl002101pjyuivd446", // 矿山
    dark: "mapbox://styles/s58383788/clexuit8d000i01p3ns0abkp2", // 北湖
    darkBlue: "mapbox://styles/s58383788/cm2vj6pxw00ea01pa9jmqd9ur", // 城市更新
    gray: "mapbox://styles/s58383788/clextlely000901qm0my0g8d5", // 北湖
    gray2: "mapbox://styles/s58383788/cljquhuwy010j01qw2kkn4btu", // 武昌建设平台
    gray3: "mapbox://styles/s58383788/cll4svei600ha01pb0i8r6f3a", // 管廊
    amap: {
        // 高德地图
        version: 8,
        sources: {
            "raster-tiles": {
                type: "raster",
                tiles: [
                    "http://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}",
                ],
                tileSize: 256,
            },
        },
        layers: [
            {
                id: "simple-tiles",
                type: "raster",
                source: "raster-tiles",
                minzoom: 0,
                maxzoom: 22,
            },
        ],
    },
    tianditu: {
        // 天地图
        version: 8,
        sources: {
            vec_w: {
                type: "raster",
                tiles: [
                    "http://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=95e0d73b7c1816ffc431ee151961db31",
                ],
                tileSize: 256,
            },
            cva_w: {
                type: "raster",
                tiles: [
                    "http://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=95e0d73b7c1816ffc431ee151961db31",
                ],
                tileSize: 256,
            },
        },
        layers: [
            {
                id: "vec_w",
                type: "raster",
                source: "vec_w",
            },
            {
                id: "cva_w",
                type: "raster",
                source: "cva_w",
            },
        ],
    },
    wukan: {
        // 武勘地图
        version: 8,
        sources: {
            "raster-tiles": {
                type: "raster",
                tiles: [
                    "https://geoserver.whupi.cn:8081/geoserver/gwc/service/wmts?layer=jbt_xht_gkb_2021:public2021new&style=&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}&authkey=71cc67f7-af41-46f6-b3a2-55fe1c7da8fd",
                ],
                tileSize: 256,
            },
        },
        layers: [
            {
                id: "simple-tiles",
                type: "raster",
                source: "raster-tiles",
                minzoom: 0,
                maxzoom: 21,
            },
        ],
        sprite: "mapbox://sprites/s58383788/cldik4eyl002101pjyuivd446/6iqitl5z21pbbxxwuij8b59i2",
        glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
    },
};
