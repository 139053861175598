import type { IViewState } from "@sonic/types";

import type { IPopup } from "./root";
import { store } from "./root";

// 分割地图
export function toggleSplitMap() {
    store.isSplit = !store.isSplit;
}

export function setViewState(payload: IViewState) {
    store.viewState = payload;
}

export function setCompareSwiper(payload: boolean) {
    store.isCompareSwiper = payload;
}

export function updateSwiperOffset(payload: number) {
    store.swiperOffset = payload;
}

export function updateShowPopup(payload: boolean) {
    store.showPopup = payload;
}

export function updatePopups(payload: IPopup) {
    store.popups.push(payload);
}

export function setHoverPopup(payload: IPopup | null) {
    store.hoverPopup = payload;
}

export function resetPopups() {
    store.popups = [];
}

// 开启地图交互
export function updateMapInteractivity(payload: boolean) {
    store.allowInteractive = payload;
}

// 设施主底图样式
export function setPrimaryMapStyle(payload: any) {
    store.primaryMapStyle = payload;
}

export function setDragRotate(payload: boolean) {
    store.dragRotate = payload;
}

export function setShowTileBoundaries(payload: boolean) {
    store.showTileBoundaries = payload;
}

export function setSprite(payload: string) {
    store.sprite = payload;
}

export function zoomIn() {
    const { zoom } = store.viewState!;
    store.viewState = { ...store.viewState!, transitionDuration: 100, zoom: zoom + 1 };
}

export function zoomOut() {
    const { zoom } = store.viewState!;
    store.viewState = { ...store.viewState!, transitionDuration: 100, zoom: zoom - 1 };
}
