import React from "react";
import { Inspector } from "react-dev-inspector";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./app";

import "virtual:uno.css";
import "antd/dist/reset.css";
import "@sonic/css-preset/styles/custom-common.scss";
import "@sonic/css-preset/styles/custom-mapbox.scss";

import "./styles/global.scss";
import "./add-plugin-and-mid";
import "./dayjs-setup";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Inspector keys={["Alt", "Z"]} />
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
);
